import styled from "styled-components";

export const TabSwitchWrapper = styled.div`
  padding: 5px;
  background: #e8eef2;
  border-radius: 30px;
  height: 100%;
`;

export const StyledTabSwitch = styled.div`
  background: #023f73;
  border-radius: 30px;
  width: 100%;
  height: 36px;
  display: flex;
  margin: 0 auto;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: ${100 / 4}%;
    height: 100%;
    background-color: #ff7200;
    border-radius: 30px;
    transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: translateX(${props => props.activeIndex * 100}%);
  }

  &.pdf-export {
    &::after {
      display: none;
    }

    .tab {
      background-color: #023f73;
      
      &.active {
        background-color: #ff7200;
        border-radius: 30px;
      }
    }
  }
`;

export const Tab = styled.div`
  cursor: pointer;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #ffffff;
  position: relative;
  z-index: 1;
  border-radius: 30px;

  span {
    position: relative;
    z-index: 2;
  }

  &.active {
    color: #ffffff;
  }

  &:not(.active):hover {
    span {
      color: #aaaaaf;
    }
  }
`;