import styled from "styled-components"

export const HeaderWrapper = styled.div`
  transition: all 0.5s;
  max-height: 50px;
  overflow: hidden;
  background: #002e57;

  &.active-menu-mobile {
    max-height: 350px;
  }

  .main-bar {
    padding: 0px 15px;
    height: 100%;
  }

  .header-content {
    max-width: 100%;
    margin: 0px;
    height: 100%;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .blue-sub-header {
    .breadcrumb-sub-header {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      margin-left: 15px;
    }
  }
  .menu-icon {
    cursor: pointer;
    transition: transform 0.5s;
    position: absolute;
    right: 10px;
    top: 10px;

    &:focus,
    &:active {
      transform: scale(2);
    }
  }

  @media (min-width: 768px) {
    .header-content {
      margin: 0 auto;
    }
  }

  @media (max-width: 590px) {
    .is-mobile {
      flex-direction: column;
      gap: 0.5em;
    }
  }

  @media (min-width: 992px) {
    max-height: 100%;
    .header-content {
      margin: 0 auto;
      display: flex;
    }
    .main-bar {
      padding: 15px 100px;
    }
    .blue-sub-header {
      display: flex;
    }
    .menu-icon {
      display: none;
    }
  }
`

export const LogoHeaderWrapper = styled.div`
  width: 100px;
  max-width: 100px;
  height: 50px;
  .logo {
    width: 130px;
  }

  @media (min-width: 992px) {
    width: 140px;
    height: 80px;
    .logo {
      width: auto;
    }
  }
`

export const TopMenuWrapper = styled.div`
  /* display: none; */
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 160px;
  justify-content: space-evenly;
  padding: 20px 0px;

  a {
    color: #ffffff;
    text-transform: uppercase;
    position: relative;
    &::before {
      content: "";
      margin-bottom: -5px;
      position: absolute;
      background: #fff;
      width: 0;
      height: 3px;
      left: 0;
      bottom: 0;
      transition: 0.5s ease;
    }
    &:hover::before {
      width: 100%;
    }
  }

  @media (min-width: 992px) {
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
`

export const ExtraNavWrapper = styled.div`
  display: block;

  @media (min-width: 1201px) {
    position: initial;
  }

  @media not all and (min-resolution:.001dpcm) { @supports (-webkit-appearance:none) {
    padding-right: 100px;
  }
`
