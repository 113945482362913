import IconDepanneur from "src/images/icons/jobs/depanneur.png"
import { ReactComponent as IconCar } from "src/images/icons/icon-car.svg"
import { ReactComponent as IconEdit } from "src/images/icons/icon-edit.svg"
import { ReactComponent as IconView } from "src/images/icons/eye.svg"
import { ReactComponent as IconRemove } from "src/images/icons/icon-remove.svg"
import { ReactComponent as IconUser } from "src/images/icons/icon-user.svg"
import { ReactComponent as IconTelephone } from "src/images/icons/icon-telephone.svg"
import { ReactComponent as IconAddress } from "src/images/icons/icon-address.svg"
import { ReactComponent as IconEmail } from "src/images/icons/icon-email.svg"
import { ReactComponent as IconPlus } from "src/images/icons/icon-plus.svg"
import { ReactComponent as IconChecked } from "src/images/icons/icon-checked.svg"
import { ReactComponent as IconPDF } from "src/images/icons/pdf-icon.svg"
import { ReactComponent as IconShare } from "src/images/icons/share-icon.svg"
export const IconCardCV = {
    Depanneur: IconDepanneur,
    Car: IconCar,
    Edit: IconEdit,
    View: IconView,
    Remove: IconRemove,
    User: IconUser,
    Telephone: IconTelephone,
    Address: IconAddress,
    Email: IconEmail,
    Plus: IconPlus,
    Checked: IconChecked,
    PDF: IconPDF,
    Share: IconShare
}