import styled, { css } from "styled-components";

function handleSwitch() {
  let styles = "";

  for (let i = 0; i < 10; i += 1) {
    styles += `&-${i} {
      .tab {
        &:first-child {
          &::before {
            transform: translateX(${i}00%);
          }
        }
      }
    }`;
  }

  return css`
    ${styles}
  `;
}

export const TabSwitchWrapper = styled.div`
  padding: 5px;
  background: #e8eef2;
  border-radius: 30px;
  height: 100%;
`;

export const StyledTabSwitch = styled.div`
  background: #023f73;
  border-radius: 30px;
  width: 100%;
  height: 36px;
  display: flex;
  margin: 0 auto;

  &.active-tab {
    ${handleSwitch()};
  }

  &.pdf-export {
    .tab {
      background-color: #023f73;
      
      &.active {
        background-color: #ff7200;
      }
    }
  }
`;

export const Tab = styled.div`
  cursor: pointer;
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #ffffff;
  background-color: transparent;
  transition: all cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.3s;
  position: relative;

  &:not(.active):hover {
    span {
      color: #aaaaaf;
    }
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  &:first-child {
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 0;
      left: 0;
      border-radius: 30px;
      background-color: #ff7200;
      transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;
    }
  }
`;