import React, { useGlobal, useMemo, useState, useEffect, useRef } from "reactn"
import { get, isEmpty } from "lodash"
import { useTranslation } from "react-i18next"
import { convertCVData, convertDetailCV } from "src/services/config"
import { useHistory } from "react-router-dom"
import jsPDF from "jspdf"
import styled from "styled-components"
import { useFormik } from "formik"
import { toast } from "react-toastify"
import html2canvas from "html2canvas"

import { Loading } from "src/markup/Element/Loading"
import CVSummary from "src/markup/Components/Cards/CVSummary"
import { MecButton } from "src/markup/Components"
import { PDFTabSwitch } from "src/markup/Components/Forms"

import {
  CardDetailsOfferWrapper,
  DetailsOfferWrapper,
  DetailsOfferLeftWrapper,
  DetailsOfferRightWrapper,
  LeftLogoWrapper,
  FooterCardWrapper,
  Footer,
  Contact,
  Informations,
  Left,
  PointsInterest,
  Profile,
  Right,
  StyledDetails,
  Prefix,
  Wrapper,
  Avatar,
} from "src/markup/Components/Cards/CardCV/styles"
import { GlobalPDFStyles } from "./styles"

import { ASSET_QUALITYMAIN_URL, ASSET_QUALITY2_URL, ASSET_QUALITY3_URL, CV_STATUS, LEVELS } from "src/constants"
import { apiCVDetail, updateCv } from "src/services/api"
import { CVFormvalidationSchema } from "./FormValidation"
import { IconCardCV } from "./icons"

const CardCV = ({ data, isDetails, handleViewDetail, view, onDelete, shareCV }) => {
  const myCvRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const history = useHistory()
  const [inexperienced, setInexperienced] = useState([])
  const [config] = useGlobal("config")
  const [user] = useGlobal("user")

  const cvDetail = useMemo(() => {
    if (config && data) {
      return convertCVData(config, data)
    }

    return null
  }, [config, data])

  const [initialFormValue, setInitialFromValue] = useState()

  useEffect(() => {
    ;(async () => {
      if (data?.id) {
        try {
          if (config) {
            const _cv = await apiCVDetail(data?.id)
            _cv && setInitialFromValue(convertDetailCV(config, _cv))
          }
        } finally {
        }
      }
    })()
  }, [data?.id, config])

  useEffect(() => {
    data?.experiences.length === 0 && setInexperienced((prevState) => [...prevState, "experiences"])
    data?.formations.length === 0 && setInexperienced((prevState) => [...prevState, "formations"])
  }, [data])

  const formik = useFormik({
    initialValues: initialFormValue,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: () => CVFormvalidationSchema({ t, isDraft: false, inexperienced }),
    onSubmit: async (values) => {
      const valuesSubmit = { ...values }
      inexperienced.map((value) => (valuesSubmit[value] = []))

      valuesSubmit.experiences = valuesSubmit.experiences.map((experience) => ({
        ...experience,
        id: null,
      }))

      valuesSubmit.formations = valuesSubmit.formations.map((formation) => ({
        ...formation,
        id: null,
        domain_activity: formation.domain_activity?.id,
        formDiploma: { id: formation.formDiploma?.id, name: formation.formDiploma?.name },
        formDiplomaOption: { id: formation.formDiplomaOption?.id, name: formation.formDiplomaOption?.name },
      }))

      valuesSubmit.mission = valuesSubmit.mission.map((mission) => ({
        ...mission,
        id: null,
        search_mission: { id: mission.search_mission?.id, name: mission.search_mission?.name },
        search_specMission: mission.search_specMission?.map((specMission) => ({
          id: specMission.id,
          name: specMission.name,
        })),
      }))

      valuesSubmit.skill = valuesSubmit.skill.map((skill) => ({
        ...skill,
        id: null,
        search_skill: { id: skill.search_skill?.id, name: skill.search_skill?.name },
        search_skillComp: skill.search_skillComp?.map((skillComp) => ({
          id: skillComp.id,
          name: skillComp.name,
        })),
      }))

      valuesSubmit.tools = valuesSubmit.tools.map((tool) => ({
        ...tool,
        keyId: null,
      }))

      valuesSubmit.search_qualityMain = valuesSubmit.search_qualityMain.map((quality) => ({
        qualityMainId: quality.qualityMainId,
        name: quality.name,
      }))

      valuesSubmit.languages = valuesSubmit.languages.map((language) => ({
        id: language.id,
        name: language.name,
      }))
      valuesSubmit.search_quality2 = valuesSubmit.search_quality2?.id
      valuesSubmit.search_quality3 = valuesSubmit.search_quality3?.id
      valuesSubmit.joblistId = valuesSubmit.joblistId?.id
      valuesSubmit.search_exp = valuesSubmit.search_exp?.id
      valuesSubmit.pres_jobOtherName = valuesSubmit.pres_jobOtherName?.id

      try {
        await updateCv({ ...valuesSubmit, id: data.id })
        toast.success("Le CV a été mis à jour avec succès")
        history.push("/candidate/mycv")
      } catch (error) {
        return error
      }
    },
  })

  const { setFieldValue, isSubmitting, handleSubmit, errors } = formik

  const handleExportPDF = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      if (view !== data.id) {
        await handleViewDetail(data.id)
        await new Promise((resolve) => setTimeout(resolve, 3000))
      }
      const element = myCvRef.current
      element.classList.add("pdf-export")

      const images = element.getElementsByTagName('img')
      await Promise.all(Array.from(images).map(img => new Promise((resolve) => {
        const newImg = new Image()
        newImg.crossOrigin = 'anonymous'
        
        newImg.onload = () => {
          try {
            const canvas = document.createElement('canvas')
            canvas.width = newImg.width
            canvas.height = newImg.height
            const ctx = canvas.getContext('2d')
            ctx.drawImage(newImg, 0, 0)
            img.src = canvas.toDataURL('image/png')
          } catch (err) {
            console.warn('Failed to cache image:', err)
          }
          resolve()
        }

        newImg.onerror = () => {
          console.warn('Failed to load image:', img.src)
          resolve()
        }

        const url = new URL(img.src, window.location.origin)
        url.searchParams.set('t', Date.now())
        newImg.src = url.toString()
      })))

      await new Promise(resolve => setTimeout(resolve, 500))

      const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true,
        allowTaint: true,
        backgroundColor: "#ffffff",
        logging: true,
        imageTimeout: 30000,
        onclone: (clonedDoc) => {
          const elements = clonedDoc.querySelectorAll("*")
          elements.forEach((el) => {
            const style = window.getComputedStyle(el)
            if (style.backgroundColor?.includes("oklch")) {
              el.style.backgroundColor = "#ffffff"
            }
            if (style.color?.includes("oklch")) {
              el.style.color = "#000000"
            }
            if (el.classList.contains('pdf-export')) {
              el.style.padding = '30px'
            }
          })

          const tabs = clonedDoc.querySelectorAll(".tab-item")
          tabs.forEach((tab) => {
            if (tab.classList.contains("active")) {
              tab.style.backgroundColor = "#ff7200"
            } else {
              tab.style.backgroundColor = "#023f73"
            }
          })

          const clonedImages = clonedDoc.getElementsByTagName('img')
          Array.from(clonedImages).forEach(img => {
            if (!img.src.startsWith('data:')) {
              console.warn('Image not converted to data URL:', img.src)
            }
          })
        },
      })

      const pdf = new jsPDF("p", "mm", "a4")
      const imgData = canvas.toDataURL("image/png")
      const imgProps = pdf.getImageProperties(imgData)
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
      const pageHeight = pdf.internal.pageSize.getHeight()
      
      const totalPages = Math.ceil(pdfHeight / pageHeight)
      
      for (let i = 0; i < totalPages; i++) {
        if (i > 0) {
          pdf.addPage()
        }

        const srcY = i * pageHeight * (imgProps.width / pdfWidth)
        const srcHeight = Math.min(
          pageHeight * (imgProps.width / pdfWidth),
          imgProps.height - srcY
        )
        
        const verticalPadding = 10
        const contentHeight = Math.min(pageHeight - (2 * verticalPadding), srcHeight * (pdfWidth / imgProps.width))
        
        pdf.addImage(
          imgData,
          "PNG",
          0,
          verticalPadding + (i === 0 ? 0 : -srcY * (pdfWidth / imgProps.width)),
          pdfWidth,
          pdfHeight,
          null,
          'FAST',
          0,
          srcY / imgProps.height,
          1,
          (srcY + srcHeight) / imgProps.height
        )
      }

      pdf.save("cv.pdf")

      element.classList.remove("pdf-export")
      toast.success("Le CV a été exporté avec succès")
    } catch (error) {
      console.error("Error exporting PDF:", error)
      toast.error("Une erreur est survenue lors de l'export du PDF")
    } finally {
      setLoading(false)
    }
  }

  return (
    <Wrapper>
      {loading && <Loading />}
      <GlobalPDFStyles />
      <Prefix> {get(cvDetail, "nameCV", "")} </Prefix>
      <CardDetailsOfferWrapper ref={myCvRef} name_cv={get(cvDetail, "nameCV", "")}>
        <DetailsOfferWrapper className="row justify-content-between">
          <DetailsOfferLeftWrapper border>
            <LeftLogoWrapper>
              <div className="d-flex justify-content-start w-100 title-offers mb-3">
                {get(data, "user.profilepicture", "") ? (
                  <Avatar className="mr-3">
                    <img src={get(data, "user.profilepicture", "")} alt="avatar" />
                  </Avatar>
                ) : (
                  <IconCardCV.User className="cv-avatar mr-3" width={100} height={100} />
                )}
                <div className="d-flex flex-column justify-content-around">
                  <span>{`${get(data, "user.surname", "")} ${get(data, "user.name", "")}`}</span>
                  <div className="d-flex align-items-center">
                    <IconCardCV.Car className="mr-2 icon-reponsive" />
                    <span className="job">{get(cvDetail, "jobName[0].name", "")}</span>
                  </div>
                </div>
              </div>
            </LeftLogoWrapper>
            <p className="job m-0">{get(cvDetail, "jobName[0].name", "")}</p>
            <CVSummary cvDetail={cvDetail} />
          </DetailsOfferLeftWrapper>

          <DetailsOfferRightWrapper className="d-flex flex-column justify-content-between" border>
            <div className="d-flex mb-3 list-skills flex-wrap">
              {get(cvDetail, "qualityMainCard", []).length > 0 ? (
                get(cvDetail, "qualityMainCard", []).map((quality) => (
                  <div className="d-flex flex-column align-items-center" key={quality.qualityMainId}>
                    <img
                      className="icon-skills mb-2 icon-reponsive"
                      src={`${ASSET_QUALITYMAIN_URL}${get(quality, "qualityMainCardValue[0].filename", null)}`}
                      alt={get(quality, "qualityMainCardValue[0].name", "")}
                    />
                    <span className="card-text-home text-first-capitalize">
                      {get(quality, "qualityMainCardValueG[0].name", "")}
                    </span>
                  </div>
                ))
              ) : (
                <IconCardCV.Plus className="dont-have-skill" />
              )}

              {get(cvDetail, "quality2Card", []).length > 0 && get(cvDetail, "quality2Card", [])?.[0] !== null ? (
                get(cvDetail, "quality2Card", []).map((quality2) => (
                  <div className="d-flex flex-column align-items-center" key={quality2?.[0]?.id}>
                    <img
                      className="icon-skills mb-2 icon-reponsive"
                      src={`${ASSET_QUALITY2_URL}${get(quality2?.[0], "filename", "")}`}
                      alt={get(quality2?.[0], "title", "")}
                    />
                    <span className="card-text-home text-first-capitalize">{get(quality2?.[0], "title", "")}</span>
                  </div>
                ))
              ) : (
                <IconCardCV.Plus className="dont-have-skill" />
              )}

              {get(cvDetail, "quality3Card", []).length > 0 && get(cvDetail, "quality3Card", [])?.[0] !== null ? (
                get(cvDetail, "quality3Card", []).map((quality3) => (
                  <div className="d-flex flex-column align-items-center" key={quality3?.[0]?.id}>
                    <img
                      className="icon-skills mb-2 icon-reponsive"
                      src={`${ASSET_QUALITY3_URL}${get(quality3?.[0], "filename", "")}`}
                      alt={get(quality3?.[0], "title", "")}
                    />
                    <span className="card-text-home text-first-capitalize">{get(quality3?.[0], "title", "")}</span>
                  </div>
                ))
              ) : (
                <IconCardCV.Plus className="dont-have-skill" />
              )}
            </div>
            <ul className="job-description infos text-blue px-4 w-100">
              <li>
                <b>Localisation :</b> {get(cvDetail, "city", "")}
              </li>
              <br></br>
              <li>
                <b>Niveau d'expérience :</b> {get(cvDetail, "levelExp[0].name", "")}
              </li>
              <br></br>
              <li>
                <b>Dernière expérience :</b>{" "}
                {get(data, "experiences", [])
                  .map((exp) => `${exp.expJobName} [${exp.expCompany}]`)
                  .join(" - ") || "Sans expérience"}
              </li>
              <br></br>
              <li>
                <b>Diplôme :</b>{" "}
                {get(cvDetail, "formations", [])
                  .map((form) => `${form?.diplomaValue?.[0]?.name || ""} ${form?.diplomaOptionValue?.[0]?.name || ""}`)
                  .join(" - ") || "Sans formation"}
              </li>
            </ul>
          </DetailsOfferRightWrapper>

          <StyledDetails active={view === data.id}>
            <div className="title mt-4">CV détaillé</div>

            <div className="row mt-0 mb-5">
              <Left className="col-12 col-xl-4">
                <Profile>
                  <h3>PROFIL</h3>
                  <div className="pl-3 pr-3">
                    Dans le cadre de mes expériences <br />
                    passées, j'ai pu me renforcer dans <br />
                    {get(cvDetail, "skills", [])
                      .map((item) => item?.[0]?.name)
                      .join(", ")}
                    . <br />
                    <br /> Maîtrisant{" "}
                    {get(cvDetail, "skillComps", [])
                      .map((item) => item?.[0]?.name)
                      .join(", ")}
                    , <br />
                    je recherche un poste dans lequel je pourrais contribuer à{" "}
                    {get(cvDetail, "mission", [])
                      .map((item) => item?.[0]?.name)
                      .join(", ")}
                    . <br /> <br />
                    Étant{" "}
                    {get(cvDetail, "qualityMain", [])
                      .map((item) => item?.[0]?.name)
                      .join(", ")}
                    , j'ai{" "}
                    {get(cvDetail, "quality2", [])
                      .map((item) => item?.[0]?.title)
                      .join(", ")}{" "}
                    et je suis{" "}
                    {get(cvDetail, "quality3", [])
                      .map((item) => item?.[0]?.name)
                      .join(", ")}
                    . Je saurais répondre à vos attentes et surtout{" "}
                    {get(cvDetail, "missionComp", [])
                      .map((item) => item?.[0]?.name)
                      .join(", ")}
                    . Je parle{" "}
                    {get(cvDetail, "languages", [])
                      .map((item) => item?.[0]?.name)
                      .join(", ")}{" "}
                    est ma langue maternelle.
                  </div>
                </Profile>

                <Contact>
                  <h3>CONTACT</h3>
                  <div className="pl-3 pr-3 content">
                    <div className="d-flex mb-3">
                      <IconCardCV.Telephone className="mr-2" width="40" height="40" />
                      <div className="d-flex flex-column">
                        <span>TÉLÉPHONE</span>
                        <span>{user?.phonenumber}</span>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <IconCardCV.Address className="mr-2" width="40" height="40" />
                      <div className="d-flex flex-column">
                        <span>ADRESSE</span>
                        <span>{user?.address}</span>
                        <span>
                          {user?.postalcode}, {user?.city}
                        </span>
                        <span>{user?.country}</span>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <IconCardCV.Email className="mr-2" width="40" height="40" />
                      <div className="d-flex flex-column">
                        <span>E-MAIL</span>
                        <span>{user?.email}</span>
                      </div>
                    </div>
                  </div>
                </Contact>

                <PointsInterest>
                  <h3>CENTRES D'INTÉRÊT</h3>
                  <div className="pl-3 pr-3">{cvDetail?.extra_otherInfos}</div>
                </PointsInterest>

                <Informations>
                  <h3>INFORMATIONS COMPLÉMENTAIRES</h3>
                  <div className="pl-3 pr-3 d-flex">
                    <div className="mr-5 d-flex flex-column">
                      {initialFormValue?.extra_driversLicenceB?.length > 0 ? (
                        initialFormValue.extra_driversLicenceB.map((item, idx) => (
                          <div key={idx} className="d-flex align-items-center">
                            <img src={IconCardCV.Depanneur} alt="icon-france" className="icon-reponsive" />
                            <span className="ml-2">{item?.name}</span>
                          </div>
                        ))
                      ) : (
                        <span>N/A</span>
                      )}
                    </div>
                  </div>
                </Informations>
              </Left>
              <Right className="col-12 col-xl-7 ml-0 ml-xl-5">
                <Profile>
                  <h3>FORMATION</h3>
                  <div className="pl-3 pr-3">
                    {Array.isArray(cvDetail?.formations) ? (
                      cvDetail.formations.map((form) => (
                        <div className="d-flex flex-column mb-3" key={form?.id || Math.random()}>
                          {(form?.diplomaValue?.[0]?.name || form?.diplomaOptionValue?.[0]?.name) && (
                            <span>
                              {form?.diplomaValue?.[0]?.name} {form?.diplomaOptionValue?.[0]?.name}
                            </span>
                          )}

                          {(form?.formSchool || form?.formEntryDate || form?.formExitDate) && (
                            <span>
                              {form?.formSchool} {form?.formEntryDate && "- " + form.formEntryDate}{" "}
                              {form?.formExitDate && "- " + form.formExitDate}
                            </span>
                          )}
                          {form?.formMissions && <span className="mt-2">{form.formMissions}</span>}
                        </div>
                      ))
                    ) : (
                      <div>Aucune formation disponible</div>
                    )}
                  </div>
                </Profile>

                <Contact>
                  <h3>PARCOURS PROFESSIONNEL</h3>
                  <div className="pl-3 pr-3">
                    {get(cvDetail, "exp", []).map((ex) => (
                      <div className="d-flex flex-column mb-3" key={ex?.id}>
                        <span>
                          {get(ex, "expJobName", "")} - {get(ex, "expCompany", "")}
                        </span>
                        <span>
                          {get(ex, "expEntryDate", "")} - {!ex.expExitDate ? "Présent" : ex.expExitDate}
                        </span>
                        <span className="mt-2">{get(ex, "expMissions", "")}</span>
                      </div>
                    ))}
                  </div>
                </Contact>

                <PointsInterest>
                  <h3>COMPÉTENCES</h3>
                  <div className="pl-3 pr-3 process-wrapper overflow-auto">
                    {get(cvDetail, "tools", []).map((tool) => (
                      <div key={tool?.[0]?.id} className="mb-3">
                        <span className="mr-5 col-12 mb-2">{get(tool, "[0].name", "")}</span>
                        <PDFTabSwitch
                          tabs={LEVELS}
                          activeTab={tool.level - 1}
                          className={`col-12 p-0 ${myCvRef.current ? "pdf-export" : ""}`}
                          name="tools"
                          style={{
                            pointerEvents: "none",
                            "--active-color": "#ff7200",
                            "--inactive-color": "#023f73",
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </PointsInterest>
              </Right>
            </div>
          </StyledDetails>

          {!isDetails && (
            <Footer className="cv-footer w-100 mt-5 justify-content-end pdf-hide-footer">
              <FooterCardWrapper onSubmit={handleSubmit}>
                {data.status === CV_STATUS.DRAFT && isEmpty(errors) && (
                  <MecButton
                    icon={IconCardCV.Checked}
                    type="primary isSubmit"
                    widthIcon={24}
                    height={44}
                    className="text-capitalize"
                    onClick={(e) => {
                      setFieldValue("status_cv", CV_STATUS.PUBLISHED)
                    }}
                  >
                    {isSubmitting ? "..." : "Publier"}
                  </MecButton>
                )}

                <MecButton
                  icon={IconCardCV.View}
                  widthIcon={24}
                  height={44}
                  type="primary"
                  className="site-button site-button-gap radius-xl text-capitalize"
                  onClick={(e) => {
                    e.preventDefault()
                    handleViewDetail(data.id)
                  }}
                >
                  {view === data.id ? "Réduire" : "Voir"}
                </MecButton>

                <MecButton
                  icon={IconCardCV.PDF}
                  widthIcon={24}
                  height={44}
                  type="primary"
                  className="site-button site-button-gap radius-xl text-capitalize"
                  onClick={handleExportPDF}
                >
                  {t("PDF")}
                </MecButton>

                <MecButton
                  icon={IconCardCV.Share}
                  widthIcon={24}
                  height={44}
                  type="primary"
                  className="site-button site-button-gap radius-xl text-capitalize"
                  onClick={(e) => {
                    e.preventDefault()
                    shareCV()
                  }}
                >
                  {t("Partager")}
                </MecButton>

                <MecButton
                  icon={IconCardCV.Edit}
                  widthIcon={24}
                  height={44}
                  type="primary"
                  className="site-button site-button-gap radius-xl text-capitalize"
                  onClick={(e) => {
                    e.preventDefault()
                    history.push(`/candidate/mycv/update/${data.id}`)
                  }}
                >
                  {t("Edit")}
                </MecButton>
                <MecButton
                  icon={IconCardCV.Remove}
                  widthIcon={24}
                  height={44}
                  type="primary"
                  className="site-button site-button-gap radius-xl text-capitalize"
                  onClick={(e) => {
                    e.preventDefault()
                    onDelete(data.id)
                  }}
                >
                  {t("Delete")}
                </MecButton>
              </FooterCardWrapper>
            </Footer>
          )}
        </DetailsOfferWrapper>
      </CardDetailsOfferWrapper>
    </Wrapper>
  )
}

export default CardCV
