import React, { Component } from "react"
// import {Link} from 'react-router-dom';
import Header from "../../Layout/Header"
import Footer from "../../Layout/Footer"

const ListItem = ({ title, items }) => (
  <div className="mb-3 ml-4">
    <h5>{title}</h5>
    <ul style={{ listStyleType: "none" }}>
      {items.map((item, index) => (
        <li key={index}>
          <b>{item.title}:</b> {item.description}
        </li>
      ))}
    </ul>
  </div>
)
class StaticHelpCandidate extends Component {
  render() {
    const preparationData = [
      {
        title: "a) Comprendre le poste et l’entreprise",
        items: [
          {
            title: "Analyser la description du poste",
            description: "relevez les mots-clés, les compétences requises et les missions principales.",
          },
          {
            title: "Se renseigner sur l’entreprise",
            description:
              "lisez son site officiel, ses réseaux sociaux, ses valeurs, sa culture d’entreprise et informez-vous sur son secteur d’activité.",
          },
          {
            title: "Tirer parti de MécanoJob",
            description:
              "explorez les offres d’emploi ciblées dans la mécanique, l’ingénierie ou la maintenance. MécanoJob vous aide à identifier rapidement les exigences techniques et les compétences recherchées.",
          },
        ],
      },
      {
        title: "b) Faire un bilan de ses compétences",
        items: [
          {
            title: "Lister ses atouts",
            description:
              "identifiez vos forces (techniques et humaines) en lien avec l’offre (par exemple, la maîtrise d’outils de CAO/FAO, la capacité à lire des plans, etc.).",
          },
          {
            title: "Identifier ses faiblesses",
            description:
              "repérez les axes d’amélioration pour préparer des réponses constructives aux questions sur vos lacunes et envisagez des formations courtes, des certifications ou des webinaires disponibles sur MécanoJob ou d’autres plateformes spécialisées.",
          },
        ],
      },
      {
        title: "c) Adapter son CV et sa lettre de motivation",
        items: [
          {
            title: "Personnaliser le contenu",
            description:
              "faites ressortir vos expériences et réalisations en lien direct avec le poste et le domaine de la mécanique.",
          },
          {
            title: "Mettre en avant les résultats",
            description:
              "utilisez des chiffres et des exemples concrets pour illustrer vos succès passés (par exemple, un pourcentage de réduction du temps d’usinage, l’optimisation du flux de production…).",
          },
          {
            title: "Soigner la mise en forme",
            description:
              "un CV clair et structuré sera plus agréable à lire pour le recruteur, et vous pouvez aussi bénéficier des conseils de MécanoJob pour le mettre en valeur.",
          },
        ],
      },
    ]

    const interviewData = [
      {
        title: "a) Préparer des réponses aux questions courantes",
        items: [
          {
            title: "Présentez-vous",
            description:
              "entraînez-vous à faire un pitch clair d’une minute mettant en avant votre parcours et vos compétences techniques.",
          },
          {
            title: "Qualités et défauts",
            description:
              "illustrez vos qualités par des exemples concrets (ex. : gestion de projet, respect des normes de sécurité) et soyez honnête sur vos défauts, en expliquant comment vous travaillez à les surmonter.",
          },
          {
            title: "Motivation",
            description:
              "démontrez votre intérêt pour le poste et l’entreprise, expliquez en quoi vos compétences répondent aux besoins exprimés. Mettez en avant votre passion pour les métiers de la mécanique, l’innovation industrielle ou la maintenance, en mentionnant éventuellement votre usage de MécanoJob pour rester informé des tendances du secteur.",
          },
        ],
      },
      {
        title: "b) Se démarquer avec des exemples concrets",
        items: [
          {
            title: "Méthode STAR (Situation, Tâche, Action, Résultat)",
            description:
              "répondez aux questions en décrivant la situation, vos responsabilités, les actions mises en place et les résultats obtenus (ex. : comment vous avez résolu une panne complexe sur une machine, comment vous avez amélioré un process de montage, etc.).",
          },
          {
            title: "Réalisations chiffrées",
            description:
              "mentionnez des indicateurs de performance (ex. : réduction du temps de montage de 20%, baisse des coûts de maintenance de 15%, etc.).",
          },
        ],
      },
      {
        title: "c) Gérer son langage corporel et sa communication",
        items: [
          {
            title: "Regard et posture",
            description:
              "adoptez une posture ouverte et regardez votre interlocuteur pour témoigner de votre assurance.",
          },
          {
            title: "Ton et clarté",
            description: "parlez distinctement et calmement, évitez de bafouiller ou de chuchoter.",
          },
          {
            title: "Écoute active",
            description:
              "montrez de l’intérêt aux propos du recruteur en hochant la tête et en posant des questions pertinentes.",
          },
        ],
      },
      {
        title: "d) Poser des questions pertinentes",
        items: [
          {
            title: "Sur l’équipe et l’entreprise",
            description: "renseignez-vous sur la culture, l’organisation du travail, les projets en cours.",
          },
          {
            title: "Sur le poste",
            description:
              " interrogez sur les missions principales, les objectifs à atteindre, le type de collaboration avec les autres services (ex. : maintenance, bureau d’études, production…).",
          },
          {
            title: "Sur l’évolution",
            description:
              "demandez quelle progression est possible au sein de l’entreprise, comment sont organisées les formations, les certifications et les promotions internes. MécanoJob peut vous inspirer pour poser des questions sur les évolutions de carrière dans le secteur mécanique.",
          },
        ],
      },
    ]

    const followUpData = [
      {
        title: "a) Faire un suivi",
        items: [
          {
            title: "Remercier le recruteur",
            description:
              "envoyez un e-mail de remerciement récapitulant votre motivation et votre intérêt pour le poste.",
          },
          {
            title: "Relancer poliment",
            description:
              "si vous n’avez pas de nouvelles après le délai annoncé, relancez avec tact pour savoir où en est le processus de recrutement.",
          },
        ],
      },
      {
        title: "b) Analyser sa performance",
        items: [
          {
            title: "Points positifs",
            description:
              "notez les éléments qui ont bien fonctionné (réponses claires, aisance relationnelle, exemples concrets).",
          },
          {
            title: "Points à améliorer",
            description:
              " identifiez les questions qui vous ont déstabilisé et préparez de meilleures réponses pour la prochaine fois. Vous pourrez également consulter les ressources d’aide à la candidature sur MécanoJob pour affiner votre stratégie.",
          },
        ],
      },
    ]

    const networkData = [
      {
        title: "a) Utiliser LinkedIn, MécanoJob et autres plateformes professionnelles",
        items: [
          {
            title: "Mettre son profil à jour",
            description:
              "ajoutez vos expériences, compétences et recommandations pour montrer votre crédibilité dans la mécanique et l’ingénierie.",
          },
          {
            title: "Participer à des groupes et des discussions",
            description:
              "commentez, partagez, réseautez avec des professionnels du secteur. MécanoJob propose des espaces d’échange et des forums où vous pouvez partager vos expériences et poser vos questions.",
          },
        ],
      },
      {
        title: "b) Bâtir et entretenir des relations",
        items: [
          {
            title: "Échanger lors d’événements",
            description:
              "soirées networking, conférences, ateliers… autant d’occasions de discuter avec des recruteurs ou des collaborateurs. Les salons professionnels liés à la mécanique ou l’industrie sont particulièrement propices aux rencontres.",
          },
          {
            title: "Faire vivre son réseau",
            description:
              "remerciez et tenez au courant vos contacts de votre évolution, proposez vos services ou expertises. Continuez à dialoguer avec les recruteurs et les experts rencontrés, notamment via les plateformes dédiées comme MécanoJob.",
          },
        ],
      },
    ]

    return (
      <div className="page-wraper">
        <Header showTitle={false} />
        <div className="page-content bg-light">
          <div className="col" style={{ textAlignLast: "center", marginBottom: "50px" }}>
            <div className="d-flex justify-content-center">
              <h1 className="m-b5 text-uppercase text-orange pt-5">Aides Candidats</h1>
            </div>
            <hr className="v3-separator" />
          </div>

          <div className="justify-content-left m-5">
            <p>
              Voici un petit guide, en plusieurs étapes, pour vous aider à maximiser vos chances d’être performants et
              vous faire embaucher
            </p>
            <h3 className="m-b5 text-uppercase text-orange">1. Préparation en amont</h3>
            {preparationData.map((section, index) => (
              <ListItem key={index} title={section.title} items={section.items} />
            ))}

            <h3 className="m-b5 text-uppercase text-orange">2. Réussir l’entretien d’embauche</h3>
            {interviewData.map((section, index) => (
              <ListItem key={index} title={section.title} items={section.items} />
            ))}

            <h3 className="m-b5 text-uppercase text-orange">3. Après l’entretien</h3>
            {followUpData.map((section, index) => (
              <ListItem key={index} title={section.title} items={section.items} />
            ))}

            <h3 className="m-b5 text-uppercase text-orange">4. Bonus : entretenir son réseau</h3>
            {networkData.map((section, index) => (
              <ListItem key={index} title={section.title} items={section.items} />
            ))}
            <p>Conclusion</p>
            <p>
              Pour être performant et maximiser ses chances de se faire embaucher, il est essentiel de se préparer avec
              soin, de bien connaître l’entreprise et le poste visé, de soigner sa présentation et de communiquer
              efficacement. Les entretiens d’embauche se préparent de manière proactive, en anticipant les questions
              courantes et en illustrant ses compétences par des exemples concrets. Enfin, un suivi attentif et une
              bonne gestion de son réseau professionnel – notamment sur MécanoJob et les autres plateformes – sont des
              éléments clés pour se différencier et réussir dans la recherche d’emploi, en particulier dans le domaine
              de la mécanique.
            </p>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default StaticHelpCandidate
