import React, { useState } from "react"
import Header from "../../Layout/Header"
import Footer from "../../Layout/Footer"
import Modal from "react-modal"
import CookieConsent from "react-cookie-consent"
import { Col } from "reactstrap"

const StaticCookies = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const instructions = [
    {
      browser: "Internet Explorer",
      url: "https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies",
    },
    {
      browser: "Firefox",
      url: "https://support.mozilla.org/fr/kb/protection-renforcee-contre-pistage-firefox-ordinateur?redirectslug=Activer+et+d%C3%A9sactiver+les+cookies&redirectlocale=fr",
    },
    {
      browser: "Google Chrome",
      url: "https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en",
    },
    {
      browser: "Safari",
      url: "https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac",
    },
  ]
  return (
    <div className="page-wraper">
      <Header showTitle={false} />
      <div className="page-content bg-light" style={{ paddingBottom: "136px" }}>
        <CookieConsent
          location="bottom"
          buttonText="Acceptez tout"
          declineButtonText="Refuser"
          enableDeclineButton
          onDecline={() => console.log("L'utilisateur refuse les cookies non essentiels.")}
          onAccept={() => console.log("L'utilisateur a accepté les cookies.")}
          style={{ background: "#2B373B", color: "white" }}
          buttonStyle={{ color: "white", backgroundColor: "#4CAF50" }}
          declineButtonStyle={{ color: "white", backgroundColor: "#f44336" }}
        >
          Nous utilisons des cookies pour personnaliser votre expérience{" "}
          <span onClick={() => setModalIsOpen(true)} style={{ textDecoration: "underline", cursor: "pointer" }}>
            Personnaliser les cookies
          </span>
        </CookieConsent>
        <Col>
          <div className="d-flex justify-content-center">
            <h1 className="m-b5 text-uppercase text-orange pt-5">Cookie</h1>
          </div>
          <hr className="v3-separator" />
          <div className="justify-content-left m-5">
            <p>
              Oovoom reçoit et enregistre des informations de votre navigateur lorsque vous utilisez la Plateforme et
              utilise des « Cookies » pour collecter ces informations. Les « Cookies » sont de petits fichiers de
              données amovibles, ne contenant aucune information personnelle, qui sont stockés par votre navigateur
              internet. Ils vous permettent de transporter des informations sur notre Plateforme sans avoir à les saisir
              à nouveau. Ils nous permettent d'analyser le trafic sur le web et d'améliorer nos services en ligne. Ils
              ne peuvent pas être utilisés pour vous identifier. Vous pouvez configurer votre navigateur web pour qu'il
              vous informe des demandes de placement de « Cookies » ou pour qu'il refuse complètement les « Cookies ».
              Vous pouvez également supprimer les fichiers qui contiennent des cookies.
            </p>
            <p>
              En cas de refus des « Cookies », Oovoom - MecanoJob ne garantit pas le fonctionnement optimal de la
              Plateforme.
            </p>
            <p>
              Le Candidat et recruteur reconnaissent et acceptent qu'Oovoom se réserve la possibilité d'implanter un «
              Cookie » dans son ordinateur ou son téléphone mobile afin d'enregistrer toute information relative à la
              navigation sur la Plateforme.
            </p>
            <ul style={{listStyleType: "upper-roman"}}>
              Si vous ne souhaitez pas qu'un « Cookie », soit implanté dans votre ordinateur ou votre téléphone mobile,
              voici la procédure à suivre en fonction du navigateur internet :
              {instructions.map((item, index) => (
                <li key={index} className="mx-4">
                  Pour {item.browser}, suivez la procédure décrite dans le lien suivant{" "}
                  <a href={item.url} target="_blank" rel="noopener noreferrer" className="text-blue-link">
                    {item.url}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </Col>
        <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}></Modal>
      </div>
      <Footer />
    </div>
  )
}

export default StaticCookies
